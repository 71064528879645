<template>
    <div>
		<b-modal ref="modalSendContract" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-else class="row">
				<div v-if="form_message !== ''" class="col-12 alert alert-warning">{{ form_message }}</div>

				<div class="col">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="template"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="labelTitleTemplate"
							:deselectLabel="deselectLabel"
							:selectedLabel="selectedLabel"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
							@input="setModel"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
					<div v-if="template">
						<a href="#" @click.prevent="showModel">{{ $t('model.afficher_model') }}</a>
					</div>

					<div class="form-group mt-4">
						<label for="mailtype_label">{{ $t('model.expeditor_label') }} *</label>
						<e-select
							v-model="finalExpeditor"
							:disabled="nomail"
							id="expeditor_id"
							:placeholder="labelTitleExpeditor"
							:options="finalExpeditors"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"        
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
					
					<b-alert show variant="warning" class="mt-2" v-if="nomail">
						<h6>{{ $t("model.personnaliser_email") }}</h6>
					</b-alert>

					<div class="form-group">
						<label for="commentaire" class="col-form-label">{{ $t("monte.commentaire") }}</label>
						<textarea id="commentaire" class="form-control" rows="3" v-model="commentaire"></textarea>
					</div>

					<div class="mb-3">
						<b-form-checkbox
						id="send_mail_sender"
						v-model="send_mail_sender"
						:value="true"
						:unchecked-value="false"
						>
						{{ $t("monte.envoie_mail_sender") }}
						</b-form-checkbox>
					</div>
	
					<div class="mb-3" v-if="askMailCourtier ">
						<b-form-checkbox
						id="send_mail_courtier"
						v-model="send_mail_courtier"
						:value="true"
						:unchecked-value="false"
						>
						{{ $t("monte.envoie_mail_courtier") }}
						{{'('+ $t("monte.mail_prefix") + courtierMail+')'}}
						</b-form-checkbox>
					</div>

					<div class="mb-3" v-if="askMailInsemination">
						<b-form-checkbox
						id="send_mail_insemination"
						v-model="send_mail_insemination"
						:value="true"
						:unchecked-value="false"
						>
						{{ $t("monte.envoie_mail_insemination") }}
						{{'('+ $t("monte.mail_prefix") + insemination_center_mail+')'}}
						</b-form-checkbox>
					</div>

					<div class="mb-3" v-if="mails_coproprios && mails_coproprios !== null">
						<b-form-checkbox
							id="send_mail_coproprios"
							v-model="send_mail_coproprios"
							:value="true"
							:unchecked-value="false"
						>
						{{ $t("monte.envoie_mail_coproprios") }}
						{{'('+ $t("monte.mail_prefix") + mails_coproprios +')'}}
						</b-form-checkbox>
					</div>

					<span>
						{{ $t('global.destinataires') }}: {{ destinataires_mails }}
					</span>


					<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
				</div>

				<div v-if="show_model" class="col-12 col-lg-6">
					<div v-if="model_selected" class="border-2 px-3 py-3" v-html="model_selected"></div>
					<LoadingSpinner v-else class="col-12" />
				</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Contact from '@/mixins/Contact.js'
import Contract from '@/mixins/Contract.js'
import Horse from '@/mixins/Horse.js'
import Model from "@/mixins/Model.js"
import { EventBus } from "EventBus"
import xss from 'xss'
import Vue from 'vue'

export default {
	name: "modalSendDocumentContract",
	mixins: [Contact, Contract, Horse, Model],
	props: ['contract_id'],
	data () {
		return {
			templates: [],
			courtierMail: null,
			insemination_center_mail: null,
			send_mail_courtier: false,
			send_mail_insemination: false,
			send_mail_sender: false,
			askMailCourtier: false,
			askMailInsemination:false,
			finalExpeditors: [],
			template: null,
			finalExpeditor: null,
			processing: false,
			document: null,
			form_message: '',
			nomail: false,
			commentaire: '',
			destinataires_mails: '',
			modal_loaded: false,
			send_mail_coproprios: false,
			mails_coproprios: null,
			model_selected: null,
			show_model: false,

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc"),
			labelTitleExpeditor: this.getTrad("model.search_expeditor"),
		}
	}, 
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = []
			this.askMailCourtier = false
			this.mails_coproprios = null
			this.model_selected = null
			this.show_model = false

			this.templates = await this.loadAllModel(0, ['contract_document_envoie'])

			await this.getContract(this.contract_id)
			.then(async contract => {
				if(contract && contract.contract_tiers.length > 0 && contract.contract_tiers[0].tiers && contract.contract_tiers[0].tiers) {
					if(contract.courtier && contract.courtier.tiers_mail && contract.courtier.tiers_mail != ""){
                        this.askMailCourtier = true
						this.courtierMail = contract.courtier.tiers_mail
                    }

					if(contract.avenant.cmep && contract.avenant.cmep.tiers_mail && contract.avenant.cmep.tiers_mail != ""){
                        this.askMailInsemination = true
						this.insemination_center_mail = contract.avenant.cmep.tiers_mail
                    }

					if(contract.avenant && contract.avenant.avenant_horse && contract.contract_tiers[0].tiers) {
						this.mails_coproprios = await this.getMailsCoproprios(contract.avenant.avenant_horse, contract.contract_tiers[0].tiers.tiers_id)
					}

					let tiers_mail = []
					if(contract.contract_tiers[0].tiers.tiers_mail != '') {
						tiers_mail.push(contract.contract_tiers[0].tiers.tiers_mail)
					}
					const resp_elevage_mails = await this.getContactByTiersAndByFonction(contract.contract_tiers[0].tiers.tiers_id, 'RESPELEV')
					const all_mails = tiers_mail.concat(resp_elevage_mails)
					this.destinataires_mails = all_mails.join(', ')
				}
			})

			const fromAddress = await this.loadAllFromAddress()
			await this.FormatFromAddress(fromAddress)

			let modal = document.getElementsByClassName('modal-dialog')[0]
			modal ? modal.classList.remove('modal-xxl') : null
			
			this.modal_loaded = true
		},
		async FormatFromAddress(fromAddress){
			let array_mail = []
			let array_name = []
			this.finalExpeditors = []
			const selected = fromAddress["selected"]
			delete fromAddress["selected"]

			for (const [key, value] of Object.entries(fromAddress)){				
				if (value.licenceconfig_key === "from_address"){
					if (value.licenceconfig_value != ''){
						const configValueAddress = value.licenceconfig_value.split(',')
						for (const address of configValueAddress){
							array_mail.push(address.trim())
						}
					}
				}
				if (value.licenceconfig_key === "from_name"){
					if (value.licenceconfig_value != ''){
						const configValueName = value.licenceconfig_value.split(',')
						for (const name of configValueName){
							array_name.push(name.trim())
						}
					}
				}
				if(array_mail.length > 0 && array_name.length > 0){
					if(array_name[0] === 'Groomy'){
						this.nomail = true
					}
					break;
				}
			}

			for (const index in array_mail){
				if(array_mail[index] === selected["email"]){
					this.finalExpeditors.unshift(array_mail[index]+ " / " + array_name[index])
				} else {
					this.finalExpeditors.push(array_mail[index]+ " / " + array_name[index])
				}
			}

			this.finalExpeditor = this.finalExpeditors[0]
		},
		openModal(doc) {
			this.document = doc
			this.form_message = ''
			this.destinataires_mails = ''
			this.$refs.modalSendContract.show()
		},
		openModalMultiple(doc) {
			this.document = doc
			this.form_message = ''
			this.destinataires_mails = ''
			this.$refs.modalSendContract.show()
		},
		closeModal() {
			this.$refs.modalSendContract.hide()
		},
		async checkForm() {
			if(this.template && this.finalExpeditor) {
				this.processing = true
				if (!this.send_mail_courtier) {
					this.courtierMail = null
				}

				if (!this.send_mail_insemination) {
					this.insemination_center_mail = null
				}

				if(!this.send_mail_coproprios) {
					this.mails_coproprios = null
				}
				if(!Array.isArray(this.document)){
					this.document = [this.document]
				}


				await this.sendMailDocumentContract(this.contract_id, this.document, this.template.model_id, this.commentaire, this.finalExpeditor, this.courtierMail, this.mails_coproprios, this.insemination_center_mail, this.send_mail_sender)
				.then(async (res) => {
					if(res) {
						this.processing = false
						this.successToast('monte.send_document_success')
						this.$emit('submit')
						EventBus.$emit("TableAction::stopSpin")
						this.closeModal()
					}
					else {
						this.processing = false
						this.failureToast('monte.send_error')
					}
				})
			}
			else {
				this.failureToast("formulaire.erreur_champs_non_remplis")
			}
		},
		async setModel() {
			this.model_selected = null

			if(this.template) {
				const models = await this.loadModel(0, this.template.model_id, this.template.type.modeltype_template)
				const templates = await this.loadTemplate(0, this.template.model_id, this.template.type.modeltype_template, Vue.i18n.locale())
				const zone = models[0].zone.find(zone => ['content_courrier_contrat_document'].includes(zone.zonetype.modelzonetype_code))
				this.model_selected = templates.find(temp => temp.modeltemplate_modelzone == zone.modelzone_id).modeltemplate_content
			}
		},
		showModel() {
			this.show_model = true
			let modal = document.getElementsByClassName('modal-dialog')[0]
			modal ? modal.classList.add('modal-xxl') : null
		},
		cleanHTML(input) {
			return xss(input)
		}
	},
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>